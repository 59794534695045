<template>
  <div class="page">
    <div class="proposal_box">
      <div class="proposal_in_box">
        <v-input
          styleStr="background: transparent"
          v-model="message"
          type="textarea"
          placeholder="发表动态，丰富社群生活..."
          :maxlength="300"
          :showWordLimit="true"
        />
      </div>
    </div>
    <div class="title">上传图片</div>
    <div class="upload_box">
      <v-upload
        class="oItem-b_upload"
        :imgUrl.sync="form.proposalPic1"
        :activityPicture.sync="form.proposalPic"
        :maxCount="3"
        ref="load"
      ></v-upload>
    </div>
    <div class="message">建议单个图片不超过5M，最多传3张图。</div>
    <div class="btn" @click="submit()">发布</div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { issueDynamicsUrl } from "./api.js";
export default {
  name: "publishingTrends",
  data() {
    return {
      message: "",
      timer: null,
      form: {
        proposalPic: "",
      },
      id: null,
    };
  },
  created() {
    this.id = this.$route.query.id;
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  methods: {
    submit() {
      if (this.message == "") {
        this.$toast("动态内容不能为空！");
        return;
      }
      if (!this.form.proposalPic) {
        this.$toast("请上传图片");
        return;
      }
      if (this.$refs.load.status == 3) {
        this.$toast("图片正在上传中");
        return;
      }
      let params = {
        createUser: this.userId,
        associationId: this.id,
        topicType: "17",
        tenantId: this.tenantId,
        topicContent: this.message,
        topicPicture: this.form.proposalPic,
      };
      this.$axios.post(`${issueDynamicsUrl}`, params).then((res) => {
        if (res.code === 200) {
          this.$toast(res.msg);
          this.timer = setTimeout(() => {
            this.$router.replace({
              name: "communitDynamic",
              query: {
                id: this.id,
              },
            });
          }, 200);
        } else {
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  min-height: 100vh;
  padding-top: 20px;
  box-sizing: border-box;

  .btn {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #ffc055 0%, #fe9514 100%);
    border-radius: 10px;
    text-align: center;
    line-height: 66px;
    font-size: 32px;
    font-weight: 600;
    color: #ffffff;
    position: fixed;
    bottom: 114px;
    left: 50%;
    transform: translateX(-50%);
  }

  .message {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.5);
    padding-top: 18px;
    padding-left: 54px;
  }

  .upload_box {
    padding-left: 54px;
  }

  .title {
    font-size: 30px;
    color: rgba(0, 0, 0, 0.5);
    padding: 42px 48px 28px 48px;
  }

  .proposal_box {
    box-sizing: border-box;
    padding: 0 48px;

    .proposal_in_box {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.04);
      border-radius: 16px;
    }
  }
}
</style>
